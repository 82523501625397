<template>
    <section id="app" class="section">
        <div class="content has-text-left">
            <p>Here are some <span v-if="minStars > 0">{{minStars}}-star </span>hotel suggestions for a stay from {{formatedIn}} to {{formatedOut}}. You can adjust the date or minimum hotel star rating and hit the update button to get suggestions that meet your requirements.</p>
                
            <div class="row">
                <div class="col">
                    <label>Dates of stay</label>
                   <flat-pickr :config="dateConfig" :value="dateStr" @on-close="updateDates"></flat-pickr>
                </div>
                <div class="col">
                    <label>Minimum rating</label>
                  <star-rating v-model="minStars" v-bind:show-rating="false" v-bind:star-size="40"></star-rating>
                </div>
                <div class="col"><button v-on:click="fetchHotels()">Update Recommendations</button></div>
            </div>
        </div>
      
    <div class="row">
      <div class="col" v-for="hotel in hotels" :key="hotel.hotelId">
          <div class="card">
              <div class="card-image">
                <a :href="hotel.landingURL" target="_blank">
                <img :src="hotel.imageURL" alt="Placeholder image"></a>
              </div>
              <div class="card-content">
                <div class="content has-text-left">
                    <h2>{{ hotel.hotelName }}<br>
                    <span v-html="starz(hotel.starRating)"></span></h2>
                    
                    <p>Starting from: ${{ hotel.dailyRate }} 
                    <span v-if="hotel.includeBreakfast == true"><svg class="bfast" viewBox="0 0 24 24">
                        <path fill="#000000" d="M2,21H20V19H2M20,8H18V5H20M20,3H4V13A4,4 0 0,0 8,17H14A4,4 0 0,0 18,13V10H20A2,2 0 0,0 22,8V5C22,3.89 21.1,3 20,3Z" />
                    </svg></span> &nbsp; 
                    <span  v-if="hotel.freeWifi == true"><svg class="wifi" viewBox="0 0 24 24">
                        <path d="M12,21L15.6,16.2C14.6,15.45 13.35,15 12,15C10.65,15 9.4,15.45 8.4,16.2L12,21M12,3C7.95,3 4.21,4.34 1.2,6.6L3,9C5.5,7.12 8.62,6 12,6C15.38,6 18.5,7.12 21,9L22.8,6.6C19.79,4.34 16.05,3 12,3M12,9C9.3,9 6.81,9.89 4.8,11.4L6.6,13.8C8.1,12.67 9.97,12 12,12C14.03,12 15.9,12.67 17.4,13.8L19.2,11.4C17.19,9.89 14.7,9 12,9Z" />
                    </svg></span></p>
                    
                    <p>Customer rating: {{ hotel.reviewScore }}<br>
                      <a :href="hotel.landingURL" target="_blank">More details / booking <svg class="chevron" viewBox="0 0 24 24">
                        <path d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z" />
                    </svg></a></p>
                </div>
              </div>
        </div>
        </div>
      </div>
    </section>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
  import flatPickr from 'vue-flatpickr-component';  
  import 'flatpickr/dist/flatpickr.css';
import StarRating from 'vue-star-rating'

export default {
  name: 'app',
  components: {
    flatPickr, StarRating
  },
  props: { city: {type: Number, default: 16917 } },
  data: () => ({
      dateIn: moment().add(30, 'days').format().substr(0, 10),
      dateOut: moment().add(33, 'days').format().substr(0, 10),
      minStars: 0,
      hotels: [],
      dateStr: '',
      dateConfig: {
          dateFormat: 'Y-m-d', mode: 'range',
          defaultDate: [moment().add(30, 'days').format().substr(0, 10), moment().add(33, 'days').format().substr(0, 10)]
      },
  }),
  computed: {
      formatedIn () {
          return moment(this.dateIn).format('D MMMM YYYY')
      },
      formatedOut () {
          return moment(this.dateOut).format('D MMMM YYYY')
      }
  },
  methods: {
      fetchHotels () {
          var params = { city: this.city, checkin: this.dateIn, checkout: this.dateOut, minstars: this.minStars }
            // eslint-disable-next-line
            // console.log(params)
          axios
              .post(process.env.VUE_APP_URL, params)
              .then(response => {
                this.hotels = response.data.results
                // eslint-disable-next-line
                // console.log(response)
              })
              .catch(error => {
              // eslint-disable-next-line
                console.log(error)
              // eslint-disable-next-line
                console.log(error.request)
              // eslint-disable-next-line
                console.log(error.data)
              // eslint-disable-next-line
                console.log(error.headers)
            })
      },
      updateDates (selectedDates) {
          this.dateIn = moment(selectedDates[0]).format('YYYY-MM-DD');
          this.dateOut = moment(selectedDates[1]).format('YYYY-MM-DD');
      },
        starz (rating) {
          var fstar = '<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#000000" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" /></svg>';
          var nstar = '<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#000000" d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" /></svg>';
          var hstar = '<svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="#000000" d="M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z" /></svg>';
          var stars = '';
          for (var is = 0; is < 5; is++) {
            if (rating > is && rating < (is + 1)) {
              stars += hstar;
            } else if (rating > is) {
              stars += fstar;
            } else {
              stars += nstar;
            }
          }
          return stars;
        }
  },
  created: function () {
    this.fetchHotels()
  }
}
</script>

<style>
    .row {
        width: 100%;
        flex-wrap: wrap;
        flex: 1 1 auto;
        display: flex;
        -webkit-box-flex: 1;
    }
    .col {
        -webkit-box-flex: 0;
        flex: 0 0 30%;
        max-width: 30%;
        padding: 12px;
    }
    .card {
        box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
        border-radius: 4px;
        position: relative;
        transition: box-shadow .28s cubic-bezier(.4,0,.2,1),-webkit-box-shadow .28s cubic-bezier(.4,0,.2,1);
        will-change: box-shadow;
        padding-bottom: 1.5em;
    }
    .content {
        flex-basis: 100%;
        -webkit-box-flex: 0;
        flex-grow: 0;
        flex: 1 1 auto;
    }
    button { background-color: #064273; padding: .5em; font-size: 1.1em; color: white; font-weight: bold;}
    section, p { text-align: left; }
    img { width: 100%; }
    .card-content { margin-left: 16px; margin-right: 16px; margin-bottom: 0; text-align: left; }
    .flatpickr-input { font-size: 1.2em; width: 100%; }
    .wifi { fill: green; width: 18px; height: 18px; }
    .bfast { width: 18px; height: 18px; }
    a { color: #005cc5; text-decoration: none; }
    .chevron { width: 24px; height: 24px; margin-bottom: -6px; fill: #005cc5; }
    @media screen and (max-width: 768px) {
        .col { width: 50%; }
    }
    @media screen and (max-width: 480px) {
        .col { width: 100%; }
    }
</style>
